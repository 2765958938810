import { ButtonTapHighlight, DialogEmergencyCall } from '../../components'
import { CarProfile } from '@tmap-web-lib/remote-api-client/frontman'
import { CodeListResData } from '@tmap-web-lib/remote-api-client/src/common'
import { useCallback, useMemo } from 'react'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { useGetInsurerInfo } from '../Etc/hook'
import { useOpenServiceUrl } from '../../hooks'
import { useDialogManager } from '@tmap-web-lib-close/dialog-manager/react-router'

interface Props {
  carProfile: CarProfile | null
  carOptionCodeList?: CodeListResData
  isCarProfileListError: boolean
  isCarOptionCodeListError: boolean
  eventLog: () => TmapLogBuilder
}

function MainEmergencyCall(props: Props) {
  const {
    carProfile,
    carOptionCodeList,
    isCarProfileListError,
    isCarOptionCodeListError,
    eventLog
  } = props
  const insurance = useGetInsurerInfo({ carProfile, carOptionCodeList })
  const openServiceUrl = useOpenServiceUrl()
  const dialogManager = useDialogManager()

  const renderState = useMemo(() => {
    if (isCarOptionCodeListError) {
      return <span className="main_emergency_error">일시적 데이터 오류</span>
    } else if (isCarProfileListError) {
      return <span className="main_emergency_other">다른 보험사 연결</span>
    } else if (insurance.insurer?.name) {
      return (
        <span className="main_emergency_insurer">
          {insurance.insurer.name}
          <img
            src={insurance.description?.iconUrl}
            width="32"
            height="32"
            className='main_emergency_logo'
            alt=""
          />
        </span>
      )
    } else {
      return (
        <span className="main_emergency_register">
          내보험사 {carProfile ? '선택' : '등록'}
        </span>
      )
    }
  }, [carProfile, insurance, isCarProfileListError, isCarOptionCodeListError])

  const handleClick = useCallback(() => {
    if (isCarProfileListError) {
      openServiceUrl('tmap://life?pageid=etc_insurer_contact')
    }
    // 보험정보가 등록된 경우에는 바텀시트로 열고 등록이 안된 경우에는 등록유도 페이지를 연다
    if (carProfile?.insurance?.insurer) {
      eventLog().set('action_id', 'tap.insurcall').addCustomField('registration_status', '1').send()
      dialogManager.showDialog({
        component: DialogEmergencyCall,
      })
      return
    } else if (carProfile) {
      eventLog().set('action_id', 'tap.insurcall').addCustomField('registration_status', '2').send()
    } else if (carProfile === null) {
      eventLog().set('action_id', 'tap.insurcall').addCustomField('registration_status', '3').send()
    }
    openServiceUrl('tmap://life?pageid=etc_emergency_call')
  }, [carProfile, isCarProfileListError, openServiceUrl, dialogManager, eventLog])

  return (
    <ButtonTapHighlight
      className="main_emergency"
      isFullWidth={false}
      disabled={isCarOptionCodeListError}
      onClick={handleClick}
    >
      <span className="main_emergency_label">긴급출동</span>
      {renderState}
    </ButtonTapHighlight>
  )
}

export { MainEmergencyCall }